@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --courier-font-family: "Courier", Helvetica;
  --courier-font-size: 18px;
  --courier-font-style: normal;
  --courier-font-weight: 400;
  --courier-letter-spacing: 0px;
  --courier-line-height: normal;
  --druk-headline-font-family: "Druk Wide Trial", Helvetica;
  --druk-headline-font-size: 32px;
  --druk-headline-font-style: normal;
  --druk-headline-font-weight: 700;
  --druk-headline-letter-spacing: 0px;
  --druk-headline-line-height: normal;
  --druk-subline-font-family: "Druk Wide Trial", Helvetica;
  --druk-subline-font-size: 29px;
  --druk-subline-font-style: normal;
  --druk-subline-font-weight: 700;
  --druk-subline-letter-spacing: 0px;
  --druk-subline-line-height: normal;
  --ff-5-4da: rgba(201, 224, 242, 1);
  --PRSNT-headlines-font-family: "Druk Wide Trial", Helvetica;
  --PRSNT-headlines-font-size: 65px;
  --PRSNT-headlines-font-style: normal;
  --PRSNT-headlines-font-weight: 700;
  --PRSNT-headlines-letter-spacing: 0px;
  --PRSNT-headlines-line-height: normal;
  --PRSNT-regular-text-font-family: "Aeonik", Helvetica;
  --PRSNT-regular-text-font-size: 25px;
  --PRSNT-regular-text-font-style: normal;
  --PRSNT-regular-text-font-weight: 400;
  --PRSNT-regular-text-letter-spacing: 0px;
  --PRSNT-regular-text-line-height: normal;
  --PRSNT-subline-font-family: "Druk Wide Trial", Helvetica;
  --PRSNT-subline-font-size: 26px;
  --PRSNT-subline-font-style: normal;
  --PRSNT-subline-font-weight: 700;
  --PRSNT-subline-letter-spacing: 0px;
  --PRSNT-subline-line-height: normal;
  --style: rgba(70, 200, 139, 1);
  --variable-collection-2: rgba(70, 200, 139, 1);
  --variable-collection-color: rgba(251, 232, 55, 1);
  --variable-collection-ff9acf: rgba(255, 154, 207, 1);
  --x-0c-25-6e: rgba(12, 37, 110, 1);
  --x-141416: rgba(20, 20, 22, 1);
  --x-7-5d-2ff: rgba(117, 210, 255, 1);
}
