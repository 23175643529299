.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-1\/2 {
  left: 50%;
}

.left-\[0\.575rem\] {
  left: .575rem;
}

.left-\[20\%\] {
  left: 20%;
}

.left-\[60\%\] {
  left: 60%;
}

.right-\[0\.575rem\] {
  right: .575rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-\[-5px\] {
  top: -5px;
}

.top-\[20\%\] {
  top: 20%;
}

.top-\[60\%\] {
  top: 60%;
}

.top-\[70\%\] {
  top: 70%;
}

.z-10 {
  z-index: 10;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-\[-1\.50px\] {
  margin-bottom: -1.5px;
}

.mb-\[-3\.00px\] {
  margin-bottom: -3px;
}

.mb-\[-6\.50px\] {
  margin-bottom: -6.5px;
}

.mb-\[-8\.00px\] {
  margin-bottom: -8px;
}

.ml-\[-1\.50px\] {
  margin-left: -1.5px;
}

.ml-\[-3\.00px\] {
  margin-left: -3px;
}

.ml-\[-60px\] {
  margin-left: -60px;
}

.mr-\[-1\.50px\] {
  margin-right: -1.5px;
}

.mr-\[-3\.00px\] {
  margin-right: -3px;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-\[-1\.50px\] {
  margin-top: -1.5px;
}

.mt-\[-3\.00px\] {
  margin-top: -3px;
}

.mt-\[-6\.50px\] {
  margin-top: -6.5px;
}

.mt-\[-8\.00px\] {
  margin-top: -8px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-4 {
  height: 1rem;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[787px\] {
  height: 787px;
}

.h-\[8rem\] {
  height: 8rem;
}

.h-full {
  height: 100%;
}

.max-h-full {
  max-height: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/5 {
  width: 20%;
}

.w-\[410px\] {
  width: 410px;
}

.w-\[424px\] {
  width: 424px;
}

.w-\[856px\] {
  width: 856px;
}

.w-\[8rem\] {
  width: 8rem;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-min {
  width: min-content;
}

.max-w-\[30rem\] {
  max-width: 30rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-\[23px\] {
  gap: 23px;
}

.gap-\[39px\] {
  gap: 39px;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[0\.5rem\] {
  border-radius: .5rem;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[80px\] {
  border-radius: 80px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#ff5ee5\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 94 229 / var(--tw-border-opacity));
}

.bg-\[\#0000003d\] {
  background-color: #0000003d;
}

.bg-\[\#ff5ee5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 94 229 / var(--tw-bg-opacity));
}

.bg-\[black\], .bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[30px\] {
  padding: 30px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-\[1rem\] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[60px\] {
  padding-left: 60px;
  padding-right: 60px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pl-\[70px\] {
  padding-left: 70px;
}

.pr-\[15px\] {
  padding-right: 15px;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[21px\] {
  font-size: 21px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-8, .leading-\[2rem\] {
  line-height: 2rem;
}

.leading-\[30px\] {
  line-height: 30px;
}

.leading-\[42px\] {
  line-height: 42px;
}

.leading-\[4rem\] {
  line-height: 4rem;
}

.leading-\[52px\] {
  line-height: 52px;
}

.leading-\[54px\] {
  line-height: 54px;
}

.leading-\[72px\] {
  line-height: 72px;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.text-\[white\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.caret-\[\#ff5ee5\] {
  caret-color: #ff5ee5;
}

.shadow-\[0px_4px_4px_\#00000040\] {
  --tw-shadow: 0px 4px 4px #00000040;
  --tw-shadow-colored: 0px 4px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_2px_\#ff5ee5\] {
  --tw-shadow: inset 0 0 0 2px #ff5ee5;
  --tw-shadow-colored: inset 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_6px_\#ff5ee5\] {
  --tw-shadow: inset 0 0 0 6px #ff5ee5;
  --tw-shadow-colored: inset 0 0 0 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[font-family\:\'Aeonik-Bold\'\,Helvetica\] {
  font-family: Aeonik-Bold, Helvetica;
}

.\[font-family\:\'Aeonik-Medium\'\,Helvetica\] {
  font-family: Aeonik-Medium, Helvetica;
}

.\[font-family\:\'Aeonik-Regular\'\,Helvetica\] {
  font-family: Aeonik-Regular, Helvetica;
}

.\[font-family\:\'Druk_Wide_Trial-Bold\'\,Helvetica\] {
  font-family: Druk Wide Trial-Bold, Helvetica;
}

:root {
  --courier-font-family: "Courier", Helvetica;
  --courier-font-size: 18px;
  --courier-font-style: normal;
  --courier-font-weight: 400;
  --courier-letter-spacing: 0px;
  --courier-line-height: normal;
  --druk-headline-font-family: "Druk Wide Trial", Helvetica;
  --druk-headline-font-size: 32px;
  --druk-headline-font-style: normal;
  --druk-headline-font-weight: 700;
  --druk-headline-letter-spacing: 0px;
  --druk-headline-line-height: normal;
  --druk-subline-font-family: "Druk Wide Trial", Helvetica;
  --druk-subline-font-size: 29px;
  --druk-subline-font-style: normal;
  --druk-subline-font-weight: 700;
  --druk-subline-letter-spacing: 0px;
  --druk-subline-line-height: normal;
  --ff-5-4da: #c9e0f2;
  --PRSNT-headlines-font-family: "Druk Wide Trial", Helvetica;
  --PRSNT-headlines-font-size: 65px;
  --PRSNT-headlines-font-style: normal;
  --PRSNT-headlines-font-weight: 700;
  --PRSNT-headlines-letter-spacing: 0px;
  --PRSNT-headlines-line-height: normal;
  --PRSNT-regular-text-font-family: "Aeonik", Helvetica;
  --PRSNT-regular-text-font-size: 25px;
  --PRSNT-regular-text-font-style: normal;
  --PRSNT-regular-text-font-weight: 400;
  --PRSNT-regular-text-letter-spacing: 0px;
  --PRSNT-regular-text-line-height: normal;
  --PRSNT-subline-font-family: "Druk Wide Trial", Helvetica;
  --PRSNT-subline-font-size: 26px;
  --PRSNT-subline-font-style: normal;
  --PRSNT-subline-font-weight: 700;
  --PRSNT-subline-letter-spacing: 0px;
  --PRSNT-subline-line-height: normal;
  --style: #46c88b;
  --variable-collection-2: #46c88b;
  --variable-collection-color: #fbe837;
  --variable-collection-ff9acf: #ff9acf;
  --x-0c-25-6e: #0c256e;
  --x-141416: #141416;
  --x-7-5d-2ff: #75d2ff;
}

@media (width >= 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-fit {
    height: fit-content;
  }

  .sm\:max-h-full {
    max-height: 100%;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:min-w-\[30rem\] {
    min-width: 30rem;
  }

  .sm\:max-w-\[40\%\] {
    max-width: 40%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:text-\[1rem\] {
    font-size: 1rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:leading-10 {
    line-height: 2.5rem;
  }

  .sm\:shadow-\[inset_0_0_0_0\.65rem_\#ff5ee5\] {
    --tw-shadow: inset 0 0 0 .65rem #ff5ee5;
    --tw-shadow-colored: inset 0 0 0 .65rem var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }
}
/*# sourceMappingURL=index.9b7b0697.css.map */
